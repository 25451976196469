import React from "react"
import { useSearchParam } from 'react-use';
import { graphql, navigate } from 'gatsby';

import { getProductUrl, getUrl } from '../../utils';

const Redirect = ({ data, pageContext }) => {
  const { products } = data;
  const { locale } = pageContext;
  const productId = useSearchParam('id');
  const variantId = useSearchParam('variant');
  // /fr/redirect/product/?id=4772140613718&variant=800640
  React.useEffect(() => {
    if (!productId) {
      return;
    }

    const product = products?.nodes.find(p => p.shopifyHumanId === productId && p.locale === locale);
    const variant = (product.variants || []).find(v => v.sku === variantId);

    const url = product ?
      getProductUrl(product.handle, locale, product.defaultVariant) :
      getUrl('/', locale);

    const args = (variant?.selectedOptions || []).filter(o => o.value && o.value !== variant.viscosity).map(o => `${o.name}=${encodeURIComponent(o.value)}`).join('&');
    navigate(`${url}${args ? `?${args}`: ''}`);
  }, [products, productId, variantId, locale]);

  return null;
}
export const query = graphql`
  query Products {
    products: allShopifyProduct {
      nodes {
        handle
        shopifyHumanId
        locale
        variants {
          sku
          selectedOptions {
            name
            value
          }
        }
      }
    }
  }
`;

export default Redirect;
